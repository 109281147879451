// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-project > form {
  width: 100%;
}
.create-project .description > textarea {
  display: none;
}

.project-list > button {
  margin-top: 1rem;
}

.cv-button__image {
  filter: invert(68%) sepia(11%) saturate(1087%) hue-rotate(90deg) brightness(97%) contrast(90%);
}
.cv-button:hover .cv-button__image {
  filter: invert(100%);
}`, "",{"version":3,"sources":["webpack://./src/pages/representative/projects.scss"],"names":[],"mappings":"AACE;EACE,WAAA;AAAJ;AAGE;EACE,aAAA;AADJ;;AAKA;EACE,gBAAA;AAFF;;AAME;EACE,8FAAA;AAHJ;AAME;EACE,oBAAA;AAJJ","sourcesContent":[".create-project {\n  & > form {\n    width: 100%;\n  }\n\n  & .description > textarea {\n    display: none;\n  }\n}\n\n.project-list > button {\n  margin-top: 1rem;\n}\n\n.cv-button {\n  &__image {\n    filter: invert(68%) sepia(11%) saturate(1087%) hue-rotate(90deg) brightness(97%) contrast(90%);\n  }\n\n  &:hover &__image {\n    filter: invert(100%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
