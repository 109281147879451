// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.events-page__header {
  margin-top: 1.5rem;
  margin-left: 1.5rem;
}
.events-page__row {
  margin-left: calc(0.5 * var(--bs-gutter-x)) !important;
  margin-right: calc(0.5 * var(--bs-gutter-x)) !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/events.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,sDAAA;EACA,uDAAA;AADJ","sourcesContent":[".events-page {\n  &__header {\n    margin-top: 1.5rem;\n    margin-left: 1.5rem;\n  }\n\n  &__row {\n    margin-left: calc(0.5 * var(--bs-gutter-x)) !important;\n    margin-right: calc(0.5 * var(--bs-gutter-x)) !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
