export default {
	"Vote": [
		"uid",
		"pids"
	],
	"UserBase": [
		"uid",
		"firstname",
		"lastname",
		"email",
		"phone"
	],
	"Student": [
		"UserBase:uid",
		"UserBase:firstname",
		"UserBase:lastname",
		"UserBase:email",
		"UserBase:phone",
		"studentnumber",
		"websites",
		"studies",
		"share",
		"manuallyShared"
	],
	"Representative": [
		"UserBase:uid",
		"UserBase:firstname",
		"UserBase:lastname",
		"UserBase:email",
		"UserBase:phone",
		"enids",
		"repAdmin"
	],
	"Admin": [
		"UserBase:uid",
		"UserBase:firstname",
		"UserBase:lastname",
		"UserBase:email",
		"UserBase:phone"
	],
	"User": [
		"UserBase:uid",
		"UserBase:firstname",
		"UserBase:lastname",
		"UserBase:email",
		"UserBase:phone",
		"Student:studentnumber",
		"Student:websites",
		"Student:studies",
		"Student:share",
		"Student:manuallyShared",
		"Representative:enids",
		"Representative:repAdmin"
	],
	"Attendance": [
		"yes",
		"no",
		"maybe"
	],
	"ApprovalStatus": [
		"awaiting",
		"approved",
		"commented",
		"rejected",
		"preliminary"
	],
	"Degree": [
		"MScAI",
		"MScCS",
		"MScCLSJD",
		"MScISIS",
		"MScISDS",
		"MScLogic",
		"MScSE",
		"MScIS"
	],
	"AcademicApprovalStatus": [
		"degree",
		"approval"
	],
	"Project": [
		"pid",
		"enid",
		"evids",
		"name",
		"degrees",
		"tags",
		"description",
		"environment",
		"expectations",
		"email",
		"numberOfStudents",
		"approval",
		"academicApproval.degree",
		"academicApproval.approval",
		"attendance",
		"datanoseLink",
		"external_id",
		"comments"
	],
	"ProjectImportResult": [
		"project.pid",
		"project.enid",
		"project.evids",
		"project.name",
		"project.degrees",
		"project.tags",
		"project.description",
		"project.environment",
		"project.expectations",
		"project.email",
		"project.numberOfStudents",
		"project.approval",
		"project.academicApproval.degree",
		"project.academicApproval.approval",
		"project.attendance",
		"project.datanoseLink",
		"project.external_id",
		"project.comments",
		"error"
	],
	"Payment": [
		"status",
		"target",
		"url",
		"amount"
	],
	"PaymentStatus": [
		"invoice",
		"open",
		"paid",
		"failed",
		"canceled",
		"expired"
	],
	"Event": [
		"evid",
		"enabled",
		"name",
		"description",
		"start",
		"end",
		"degrees",
		"location",
		"studentSubmitDeadline",
		"entities",
		"external_id",
		"isMarketplace",
		"deadlinePassed"
	],
	"EventImportResult": [
		"event.evid",
		"event.enabled",
		"event.name",
		"event.description",
		"event.start",
		"event.end",
		"event.degrees",
		"event.location",
		"event.studentSubmitDeadline",
		"event.entities",
		"event.external_id",
		"event.isMarketplace",
		"event.deadlinePassed",
		"error"
	],
	"EntityContactInfo": [
		"type",
		"content"
	],
	"Entity": [
		"enid",
		"name",
		"description",
		"type",
		"contact.type",
		"contact.content",
		"external_id",
		"representatives",
		"location",
		"payments.status",
		"payments.url",
		"payments.eventDate",
		"evids",
		"grantsAcademicRights"
	],
	"BarePaymentInfo": [
		"status",
		"url",
		"eventDate"
	],
	"EntityImportResult": [
		"entity.enid",
		"entity.name",
		"entity.description",
		"entity.type",
		"entity.contact.type",
		"entity.contact.content",
		"entity.external_id",
		"entity.representatives",
		"entity.location",
		"entity.payments.status",
		"entity.payments.url",
		"entity.payments.eventDate",
		"entity.evids",
		"entity.grantsAcademicRights",
		"error"
	]
}
