// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-card {
  width: 24rem;
  height: 34rem;
  border-radius: 1rem !important;
  overflow: hidden;
}
.event-card__subtitle {
  margin-bottom: 1rem !important;
}
.event-card__image {
  width: 100%;
  height: 10rem;
  object-fit: cover;
}
.event-card--no-image .event-card__image {
  display: none;
}
.event-card--no-image .event-card__body {
  height: 24rem;
}
.event-card__body {
  display: flex;
  flex-direction: column;
  height: 14rem;
  margin-bottom: 0 !important;
  overflow: hidden;
  --mask: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 0,
      rgba(0, 0, 0, 1) 75%,
      rgba(0, 0, 0, 0) 100%,
      rgba(0, 0, 0, 0) 0
    )
    100% 50% / 100% 100% repeat-x;
  mask: var(--mask);
}
.event-card__tags {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: left;
  margin-right: 0.5rem;
  row-gap: 0.25rem;
}
.event-card__tags:not(:empty) {
  margin-bottom: 0.75rem;
}`, "",{"version":3,"sources":["webpack://./src/components/eventCard/eventCard.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EAEA,8BAAA;EACA,gBAAA;AAAF;AAEE;EACE,8BAAA;AAAJ;AAGE;EACE,WAAA;EACA,aAAA;EACA,iBAAA;AADJ;AAKI;EACE,aAAA;AAHN;AAMI;EACE,aAAA;AAJN;AAQE;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,2BAAA;EACA,gBAAA;EAEA;;;;;;;iCAAA;EAUA,iBAAA;AARJ;AAWE;EACE,aAAA;EACA,WAAA;EACA,eAAA;EACA,qBAAA;EACA,oBAAA;EACA,gBAAA;AATJ;AAWI;EACE,sBAAA;AATN","sourcesContent":[".event-card {\n  width: 24rem;\n  height: 34rem;\n\n  border-radius: 1rem !important;\n  overflow: hidden;\n\n  &__subtitle {\n    margin-bottom: 1rem !important;\n  }\n\n  &__image {\n    width: 100%;\n    height: 10rem;\n    object-fit: cover;\n  }\n\n  &--no-image {\n    & .event-card__image {\n      display: none;\n    }\n\n    & .event-card__body {\n      height: 24rem;\n    }\n  }\n\n  &__body {\n    display: flex;\n    flex-direction: column;\n    height: 14rem;\n    margin-bottom: 0 !important;\n    overflow: hidden;\n\n    --mask: linear-gradient(\n        to bottom,\n        rgba(0, 0, 0, 1) 0,\n        rgba(0, 0, 0, 1) 75%,\n        rgba(0, 0, 0, 0) 100%,\n        rgba(0, 0, 0, 0) 0\n      )\n      100% 50% / 100% 100% repeat-x;\n\n    -webkit-mask: var(--mask);\n    mask: var(--mask);\n  }\n\n  &__tags {\n    display: flex;\n    width: 100%;\n    flex-wrap: wrap;\n    justify-content: left;\n    margin-right: 0.5rem;\n    row-gap: 0.25rem;\n\n    &:not(:empty) {\n      margin-bottom: 0.75rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
