// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button--danger-delete {
  background-color: #bc1a08 !important;
  color: #fff !important;
  border: 1px solid #bc1a08 !important;
}
.button--danger-delete:hover {
  background-color: #ab1403 !important;
  border: 1px solid #ab1403 !important;
}
.button--danger-delete:active {
  background-color: #9c1000 !important;
  border: 1px solid #9c1000 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/entityEditor/style.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,sBAAA;EACA,oCAAA;AACF;AACE;EACE,oCAAA;EACA,oCAAA;AACJ;AAEE;EACE,oCAAA;EACA,oCAAA;AAAJ","sourcesContent":[".button--danger-delete {\n  background-color: #bc1a08 !important;\n  color: #fff !important;\n  border: 1px solid #bc1a08 !important;\n\n  &:hover {\n    background-color: #ab1403 !important;\n    border: 1px solid #ab1403 !important;\n  }\n\n  &:active {\n    background-color: #9c1000 !important;\n    border: 1px solid #9c1000 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
